










































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfSelect } from '@storefront-ui/vue';
import { useUiHelpers, useUiState } from '~/composables';
import { Pagination } from '~/composables/types';
import { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';

export default defineComponent({
  components: {
    SfSelect,
  },
  props: {
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: (): Pagination => ({}),
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const {
      toggleFilterSidebar,
      isFilterSidebarOpen,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
    } = useUiState();
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      emit('reloadProducts');
    };
    return {
      toggleFilterSidebar,
      isFilterSidebarOpen,
      doChangeSorting,
      ...uiHelpers,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
    };
  },
  computed: {
    itemsCount(): string {
      const { pagination } = this;
      const count = pagination.totalItems;
      return `${count} ${count === 1 ? 'item' : 'items'}`;
    },
  },
});
