import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';

import type { ShippingCartAddress, CustomerAddress } from '~/modules/GraphQL/types';

import { emailValidator } from '~/utilities/validators';

import axios from 'axios';
import type {
  UseShippingErrors, UseShippingInterface, UseShippingLoadParams, UseShippingSaveParams,
} from './useShipping';

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
export function useShipping(): UseShippingInterface {
  const INVALID_ADDRESS_INDICATOR = '_';
  const loading = ref(false);
  const error = ref<UseShippingErrors>({ load: null, save: null });
  const { cart, load: loadCart } = useCart();
  const { app } = useContext();

  const load = async (params: UseShippingLoadParams = {}): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.load');
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;
      if (cart?.value?.shipping_addresses.length === 0) {
        await loadCart(params);
      }

      [shippingInfo] = cart.value.shipping_addresses;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShipping/load', err);
    } finally {
      loading.value = false;
    }
    return shippingInfo;
  };

  const save = async ({ shippingDetails }: UseShippingSaveParams): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.save');
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;

      const { id } = cart.value;

      const {
        apartment, neighborhood, extra, customerAddressId, ...address
      } = shippingDetails;

      const shippingData = customerAddressId
        ? { customer_address_id: customerAddressId }
        : {
          address: {
            ...address,
            street: [address.street, apartment, neighborhood, extra].filter(Boolean),
          },
        };

      const shippingAddressInput = {
        cart_id: id,
        shipping_addresses: [
          {
            ...shippingData,
          },
        ],
      };

      const { data } = await app.$vsf.$magento.api.setShippingAddressesOnCart(shippingAddressInput);

      Logger.debug('[Result]:', { data });

      [shippingInfo] = data.setShippingAddressesOnCart.cart.shipping_addresses;
      await loadCart();

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo;
  };

  const searchAddresses = async (search: string): Promise<any> => {
    const SEARCH_QUERY = search.replace(/\s/g, '+');
    // eslint-disable-next-line prefer-destructuring
    const SMARTY_API_KEY = process.env.SMARTY_API_KEY;
    const SMARTY_BASE_API_URL = 'https://us-autocomplete-pro.api.smarty.com';
    const SMARTY_SEARCH_URL = `${SMARTY_BASE_API_URL}/lookup?key=${SMARTY_API_KEY}&search=${SEARCH_QUERY}&prefer_geolocation=city`;

    let suggestions = [];
    if (search.length > 5) {
      try {
        const { data } = await axios.get(SMARTY_SEARCH_URL);
        suggestions = data.suggestions;
      } catch {
        suggestions = [];
      }
    }

    return suggestions;
  };

  const validateAddress = async (search: string): Promise<any> => {
    // eslint-disable-next-line prefer-destructuring
    const GOOGLE_ADDRESS_VALIDATION_API_KEY = process.env.GOOGLE_ADDRESS_VALIDATION_API_KEY;
    const GOOGLE_ADDRESS_VALIDATION_BASE_API_URL = 'https://addressvalidation.googleapis.com';
    const GOOGLE_SEARCH_URL = `${GOOGLE_ADDRESS_VALIDATION_BASE_API_URL}/v1:validateAddress?key=${GOOGLE_ADDRESS_VALIDATION_API_KEY}`;

    let addressComponents;
    let verdict;

    if (search.length >= 22) {
      try {
        const { data } = await axios.post(GOOGLE_SEARCH_URL, {
          address: {
            regionCode: 'US',
            addressLines: [search],
          },
        });
        addressComponents = data?.result?.address?.addressComponents;
        verdict = data?.result?.verdict;
      } catch (err: any) {
        console.error(err);
        addressComponents = undefined;
        verdict = undefined;
      }
    }

    return [addressComponents, verdict];
  };

  const validateEmailAPI = async (email: string): Promise<boolean> => {
    // eslint-disable-next-line prefer-destructuring
    const ABSTRACTAPI_API_KEY = process.env.ABSTRACTAPI_API_KEY;
    const ABSTRACTAPI_API_BASE_API_URL = 'https://emailvalidation.abstractapi.com';
    const ABSTRACTAPI_API_SEARCH_URL = `${ABSTRACTAPI_API_BASE_API_URL}/v1/?api_key=${ABSTRACTAPI_API_KEY}&email=${email}`;

    let isValidEmail = false;
    try {
      const { data }: { data: { is_valid_format: { value: boolean } } } = await axios.get(ABSTRACTAPI_API_SEARCH_URL);
      isValidEmail = data.is_valid_format.value;
    } catch (err: any) {
      console.error(err);
      isValidEmail = emailValidator(email);
    }
    return isValidEmail;
  };

  const invalidAddressFormat = (addressComponents: Array<any>, verdict: { hasUnconfirmedComponents: Boolean } | undefined) => {
    const confirmedComponents = addressComponents.filter(({ confirmationLevel }) => ['CONFIRMED', 'UNCONFIRMED_BUT_PLAUSIBLE'].includes(confirmationLevel as string));
    const _street_number = confirmedComponents.find(({ componentType }) => componentType === 'street_number');
    const _route = confirmedComponents.find(({ componentType }) => componentType === 'route');
    const _point_of_interest = confirmedComponents.find(({ componentType }) => componentType === 'point_of_interest');

    const street = [];
    if (_street_number) {
      const streetNumber = _street_number?.componentName?.text.trim();
      if (streetNumber) {
        street.push(streetNumber);
      }
    }
    if (_route) {
      const route = _route?.componentName?.text;
      if (route) {
        street.push(route);
      }
    }
    if (!!_point_of_interest && street.length !== 2) {
      const POI = _point_of_interest?.componentName?.text.trim();
      if (POI) {
        street.push(POI);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _apartment = confirmedComponents.find(({ componentType }) => componentType === 'subpremise');
    const _city = confirmedComponents.find(({ componentType }) => componentType === 'locality');
    const _region = confirmedComponents.find(({ componentType }) => ['administrativeArea', 'administrative_area_level_1'].includes(componentType as string));
    const _postal_code = confirmedComponents.find(({ componentType }) => componentType === 'postal_code');
    const _postal_code_suffix = confirmedComponents.find(({ componentType }) => componentType === 'postal_code_suffix');

    let postcode;
    if (_postal_code) {
      postcode = `${_postal_code?.componentName?.text}`.trim();
    }

    if (_postal_code_suffix) {
      const _suffix = '-';
      postcode = `${postcode}${_suffix}${_postal_code_suffix?.componentName?.text}`.trim();
    }

    if (_postal_code === undefined) {
      postcode = 'UNDEFINED_POST_CODE';
    }

    let extra;
    if (!!verdict && !!verdict?.hasUnconfirmedComponents) {
      extra = INVALID_ADDRESS_INDICATOR;
    }

    const formattedAddress: CustomerAddress = {
      street,
      city: _city?.componentName?.text,
      region: {
        region_code: _region?.componentName?.text,
      },
      postcode,
    };
    if (extra) {
      formattedAddress.extra = extra;
    }
    return formattedAddress;
  };

  return {
    load,
    save,
    searchAddresses,
    error: readonly(error),
    loading: readonly(loading),
    validateAddress,
    validateEmailAPI,
    invalidAddressFormat,
  };
}

export * from './useShipping';
export default useShipping;
