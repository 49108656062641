


































































































































import {
  defineComponent, ref, watch, ssrRef,
} from '@nuxtjs/composition-api';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';

import type { Product } from '~/modules/catalog/product/types';

import facetGetters from '~/modules/catalog/category/getters/facetGetters';

import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import { usePrice } from '~/modules/catalog/pricing/usePrice';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

import type { UseFacetSearchResult } from '~/modules/catalog/category/composables/useFacet/useFacet';
import type { ProductInterface } from '~/modules/GraphQL/types';

import CategoryNavbar from '~/modules/catalog/category/components/navbar/CategoryNavbar.vue';
import SearchBar from './SearchBar.vue';

export default defineComponent({
  components: {
    CategoryNavbar,
    SearchBar,
    CategoryFilters: () => import(
      '~/modules/catalog/category/components/filters/CategoryFilters.vue'
    ),
    CategoryProductGrid: () => import(
      '~/modules/catalog/category/components/views/CategoryProductGrid.vue'
    ),
  },
  props: {
    popularSearch: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { toggleFilterSidebar, isFilterSidebarOpen } = useUiState();

    const uiHelpers = useUiHelpers();

    const { addItemToCart } = useAddToCart();
    const {
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();

    const refSearchbar = ref(null);
    const isShowProducts = ref(false);
    const isSearchResults = ref(false);

    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({});

    const products = ssrRef<ProductInterface[]>([]);

    const isPriceLoaded = ref(false);

    const reloadProducts = () => {
      const { filters } = { ...uiHelpers.getFacetsFromURL() };
      if (Object.keys(filters).length > 0) {
        toggleFilterSidebar();
      }

      refSearchbar.value.rawHandleSearch(null, filters);
    };

    const toggleSidebarTransition = ref(false);

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };

    const loadResult = (result: UseFacetSearchResult) => {
      products.value = facetGetters.getProducts(result) ?? [];
      sortBy.value = facetGetters.getSortOptions(result);
      pagination.value = facetGetters.getPagination(result);

      isPriceLoaded.value = true;
      isSearchResults.value = true;
      isShowProducts.value = true;
    };

    watch(isFilterSidebarOpen, (value) => {
      if (value) {
        setTimeout(() => {
          toggleSidebarTransition.value = value;
        }, 150);
      } else {
        toggleSidebarTransition.value = value;
      }
    });

    const startSearch = () => {
      emit('start-search');
    };

    return {
      addItemToWishlist,
      loadResult,
      startSearch,
      reloadProducts,
      toggleFilterSidebar,
      addItemToCart,
      refSearchbar,
      toggleSidebarTransition,
      isPriceLoaded,
      isShowProducts,
      isFilterSidebarOpen,
      isSearchResults,
      products,
      sortBy,
      pagination,
    };
  },
});
