import axios from 'axios';
import {
  useContext,
} from '@nuxtjs/composition-api';

type TradeprogramForm = {
  firstname: string,
  lastname: string,
  company: string,
  commercialFocus: string,
  email: string,
  phone: string,
  businessAddress: string,
  website: string,
  reason: string,
};
export function useMailer(): any {
  const { app } = useContext();

  async function sendTradeprogramMail(formData : TradeprogramForm): Promise<any> {
    const ENDPOINT = 'mail/trade-program';
    // const BASE_URL = app.$vsf.$magento.config.axios.baseURL || process.env.API_BASE_URL;
    const BASE_URL = process.env.API_BASE_URL;

    const client = axios.create({
      baseURL: BASE_URL,
    });

    const url = `${BASE_URL}${ENDPOINT}`;

    await client.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return {
    sendTradeprogramMail,
  };
}

export default useMailer;
